<template>
  <label class="relative flex cursor-pointer gap-3">
    <div>
      <input
        type="checkbox"
        :name="name"
        :class="{
          checkbox: type === 'checkbox',
          'toggle toggle-xs border-0 bg-ui_white [--tglbg:#F2F1F7] md:toggle-sm 2xl:toggle-md checked:bg-ui_white checked:[--tglbg:#F7E017] hover:bg-ui_white':
            type === 'toggle',
          '!bg-ui_yellow': value && type === 'checkbox',
        }"
        :checked="modelValue"
        :disabled="disabled"
        @input="changed"
      />
    </div>
    <span
      v-if="!$slots.default"
      class="label-text"
    >
      {{ text }}
    </span>
    <slot v-else>{{ text }}</slot>
  </label>
</template>

<script setup>
defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    required: false,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'checkbox',
    validate: (type) => ['checkbox', 'toggle'].includes(type),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);
const value = ref(false);
const changed = (event) => {
  value.value = event.target.checked;
  emit('update:modelValue', event.target.checked);
};
</script>

<style setup>
.checkbox:checked,
.checkbox[checked='true'],
.checkbox[aria-checked='true'] {
  --chkbg: #f7e017;
  --chkfg: #1f1f1f;
}
.checkbox {
  border: 2px solid #f7e017;
}
</style>
